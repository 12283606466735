import React, { useEffect,useState } from "react";
import axios from "axios";
import {
  BrowserRouter,
  Routes,
  Route,
  useSearchParams,
} from "react-router-dom";
import ReactLoading from "react-loading";

const { REACT_APP_API_URI } = process.env;

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ApprovalMailProcessingPage />} />
        <Route path="/pass" element={<EmailConfirmation />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

const ApprovalMailProcessingPage = () => {
  const [serchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const endPoint = serchParams.get("endpoint");
    const status = serchParams.get("status");

    const recordNo = serchParams.get("recordNo");
    const projectCode = serchParams.get("projectCode");
    const lineItemNO = serchParams.get("lineItemNO");
    const user = serchParams.get("user");
    const idx = serchParams.get("idx");
    const confirmation = serchParams.get("confirmation");
    const comment = serchParams.get("comment");

    update_approval_status(
      endPoint,
      status,
      projectCode,
      recordNo,
      lineItemNO,
      user,
      idx,confirmation, comment
    ).then(() => {
      setIsLoading(false); // 요청 완료 시 로딩 상태 업데이트
    });
  }, [serchParams]);
  
  if (isLoading) {
    // 로딩 중일 때 보여줄 UI
    return (
      <div
        style={{
          display: "flex",
          height: "500px",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ReactLoading
          type="spinningBubbles"
          color="#484848"
          height={150}
          width={150}
        />

        <div style={{ marginTop: "30px", fontWeight: "bold" }}>
          처리 중입니다. 잠시만 기다려주세요.
        </div>
      </div>
    );
  }

  // 로딩이 완료되면 빈 페이지 혹은 다른 내용 표시
  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>처리가 완료되었습니다.</div>;
};

const update_approval_status = async (
  endPoint,
      status,
      projectCode,
      recordNo,
      lineItemNO,
      user,
      idx,confirmation, comment
) => {
  let message;

  try {
    const update_approval_process = await axios
      .post(`${REACT_APP_API_URI}/${endPoint}`, {
        status,
        projectCode,
        recordNo,
        lineItemNO,
        user,
        idx,confirmation, comment
      })
      .then((data) => data.data);

    message = update_approval_process.message;
  } catch (err) {
    console.log(err);
    message =
      "처리 중 에러가 발생했습니다. 잠시 후 다시 시도해 주시기 바랍니다.";
  } finally {
    alert(message);
    window.close();
  }
};

const EmailConfirmation = () => {

  const [finalUrl, setFinalUrl] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [userInput, setUserInput] = useState('');
  const [comment, setComment] = useState(''); // 코멘트를 위한 새로운 상태 변수
  console.log(comment)

  useEffect(() => {
    const urlParam = getUrlParameter('redirect');
    setFinalUrl(urlParam);

    const queryParams = new URLSearchParams(urlParam);
    const status = queryParams.get('status');
    setStatusValue(status);
  }, []);

  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };


  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value); // 코멘트 입력 핸들러
  };

  const confirmEmail = () => {
    if (userInput === "결재합니다"|| userInput ==="반려합니다") {
      const separator = finalUrl.includes('?') ? '&' : '?';
      const newUrl = `${finalUrl}${separator}confirmation=${encodeURIComponent(userInput)}&comment=${encodeURIComponent(comment)}`;

      console.log(newUrl)

      window.location.href = newUrl;
    } else {
      alert("올바른 문구를 입력해주세요.");
    }
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <p>이메일을 확인하셨나요? 아래 버튼을 클릭하면 결재가 진행됩니다.</p>
      <input 
        type="text" 
        onChange={handleInputChange} 
        placeholder={statusValue === "Approved"? '"결재합니다"를 입력하세요':'"반려합니다"를 입력하세요'}
        style={{
          padding: '10px',
          margin: '10px 0',
          fontSize: '16px',
          border: '2px solid #ccc',
          borderRadius: '5px',
          outline: 'none',
          transition: 'border-color 0.3s'
        }}
        onFocus={(e) => e.target.style.borderColor = '#007bff'}
        onBlur={(e) => e.target.style.borderColor = '#ccc'}
      />
      <textarea 
        onChange={handleCommentChange} 
        placeholder="코멘트를 입력하세요"
        style={{
          width: '300px', // 너비 조절
          height: '100px', // 높이 조절
          padding: '10px',
          margin: '10px 0',
          fontSize: '16px',
          border: '2px solid #ccc',
          borderRadius: '5px',
          outline: 'none',
          transition: 'border-color 0.3s'
        }}
        onFocus={(e) => e.target.style.borderColor = '#007bff'}
        onBlur={(e) => e.target.style.borderColor = '#ccc'}
      ></textarea>
      <br/>
      <button onClick={confirmEmail} disabled={(statusValue === "Approved"&& userInput !== "결재합니다") || (statusValue === "Rejected"&& userInput !== "반려합니다") }>확인</button>
    </div>
  );
};

